import { UserInterview, IPostData, InterviewSource } from "eatl.connectlite.nien.web.userinterview.component"
import React from "react";
import { Container } from "react-bootstrap";
import "eatl.connectlite.nien.web.userinterview.component"
import { InterviewParam } from "../../models/models";
const { REACT_APP_CAPTCHA_KEY } = process.env;

export interface IInterviewWrapper {
    SubmissionCallback: (interviewdata: IPostData) => Promise<void>,
    ResetCallback: () => void,
    submitting: boolean,
    interviewParams: InterviewParam[]
};

export default class InterviewWrapper extends React.Component<IInterviewWrapper>{

    private _interviewParams: any;

    constructor(props: IInterviewWrapper) {
        super(props);

        this._interviewParams = props.interviewParams;
    }

    public render =
    () => <Container className="mt-2">
                <UserInterview
                    SubmissionCallback={this.checkRunCallback.bind(this)}
                    MaxDomestic={this._interviewParams?.MaxDomestic ?? 4}
                    MaxApartments={this._interviewParams?.MaxApartments ?? 5}
                    CommercialManualLink={this._interviewParams?.CommercialManualLink ?? "https://www.nienetworks.co.uk/connections/business-connection"}
                    CommercialManualLinkText={this._interviewParams?.CommercialManualLinkText ?? 'request estimate'}
                    DomesticManualLink={this._interviewParams?.DomesticManualLink ?? 'https://www.nienetworks.co.uk/connections/home-connection'}
                    DomesticManualLinkText={this._interviewParams?.DomesticManualLinkText ?? 'request estimate'}
                    MaxDomesticMessage={this._interviewParams?.MaxDomesticMessage ?? 'The requested connection size exceeds the capacity limit for this budget estimation tool, please use the process at the link to request an estimate.'}
                    MaxCommercialMessage={this._interviewParams?.MaxCommercialMessage ?? "The requested connection size exceeds the capacity limit for this budget estimation tool, please use the process at the link to request an estimate."}
                    MaxCommercialKVA={this._interviewParams?.MaxCommercialKVA ?? 69}
                    MaxCommercialSingleKVA={this._interviewParams?.MaxCommercialSingleKVA ?? 23}
                    MaxApartmentsKVA={this._interviewParams?.MaxApartmentsKVA ?? 69}
                    MaxDomesticKVA={this._interviewParams?.MaxDomesticKVA ?? 23}
                    MaxEVSingle={this._interviewParams?.MaxEVSingle ?? 7.2}
                    DomesticPhaseSelect={this._interviewParams?.DomesticPhaseSelect ?? false}
                    CaptchaKey={REACT_APP_CAPTCHA_KEY}
                    PostcodeValidationRegExpPattern={this._interviewParams?.PostcodeValidationRegExpPattern}
                    PostcodeValidationRegExpMessage={this._interviewParams?.PostcodeValidationRegExpMessage ?? 'Please enter a postcode serviced by Northern Ireland Electricity Networks'}
                    PostcodeValidationRemoteEndpoint={this._interviewParams?.PostcodeValidationRemoteEndpoint}
                    PostcodeValidationRemoteMethod={this._interviewParams?.PostcodeValidationRemoteMethod ?? 'get'}
                    PostcodeValidationRemoteMessage={this._interviewParams?.PostcodeValidationRemoteMessage ?? 'Please enter a postcode serviced by Northern Ireland Electricity Networks'}
                    PostcodeValidationRemoteCrossDomain={this._interviewParams?.PostcodeValidationRemoteCrossDomain ?? false}
                    Submitting={this.props.submitting}
                    ResetCallback={this.props.ResetCallback.bind(this)}
                    InterviewSource={InterviewSource.External}
                />
            </Container>


    private async checkRunCallback(interviewdata: IPostData) : Promise<void> {
        if (this.props.SubmissionCallback) {
            await this.props.SubmissionCallback(interviewdata);
        }
    }
}