import React, { useState } from "react"
import { Button, ButtonProps, Modal } from "react-bootstrap"
import RestartModal from "./RetartModal";

interface IRestartButtonProps {
    RestartCallback: () => void,
    ButtonVariant?: ButtonProps["variant"],
    ButtonDisabled?: boolean,
    ButtonText?: string,
    ButtonDisplayBlock?: boolean,
    ButtonSize?: ButtonProps["size"],
    ButtonClassName?: string,
    ConfirmWithModal?: boolean,
    ModalCancelButtonText?: string,
    ModalMessageText?: string,
}

const RestartButtonWithModal = ({
    RestartCallback,
    ButtonVariant = "secondary",
    ButtonDisabled = false,
    ButtonText = "Restart",
    ButtonDisplayBlock = false,
    ButtonSize = undefined,
    ButtonClassName = undefined,
    ConfirmWithModal = false,
    ModalCancelButtonText = "Cancel",
    ModalMessageText = `Are you sure you want to ${ButtonText.toLowerCase()}? All progress will be lost!`,
}: IRestartButtonProps) => {
    const [showModal, setShowModal] = useState<boolean>(false);

    return <React.Fragment>
        <Button
            block={ButtonDisplayBlock}
            size={ButtonSize}
            type="button"
            variant={ButtonVariant}
            onClick={() => (ConfirmWithModal) ? setShowModal(true) : RestartCallback()}
            disabled={ButtonDisabled}
            className={ButtonClassName}
          >
          {ButtonText}
        </Button>
        <RestartModal
          Show={showModal}
          RestartCallback={RestartCallback}
          HideModalCallback={() => setShowModal(false)}
          ButtonText={ButtonText}
          ModalCancelButtonText={ModalCancelButtonText}
          ModalMessageText={ModalMessageText}
        />
    </React.Fragment>
}

export default RestartButtonWithModal;