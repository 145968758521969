import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "./store";
import { Inputs, meterType } from "../models/models";
import { ChangeEvent } from "react";
import { EventKey } from "react-bootstrap/esm/types";
import { Phases } from "eatl.connectlite.nien.web.userinterview.component";

// Define a type for the slice state
export interface AppState {
  inputs: Inputs;
  studyType: EventKey;
}

// Define the initial state using that type
export const initialState: AppState = {
  inputs: {
    quotationRef: "",
    connectionMethod: "manual",
    singlePhaseConnections: 0,
    threePhaseConnections: 0,
    connections: 0,
    multiSwitchConnections: [],
    searchType: "Postcode",
    searchValue: "",
    isNewStudy: true,
    existingStudy: undefined,
    isSiteExcavationCostIncluded: false,
    totalkVA: 0,
    userRole: "external"
  },
  studyType: "newStudy",
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setStudyType: (state, action: PayloadAction<EventKey>) => {
      if (state.studyType === action.payload) return;
      state.inputs = { ...initialState.inputs };
      let inputs: any = state.inputs;
      inputs.isNewStudy = action.payload === "newStudy";
      state.studyType = action.payload;
    },
    updateInputsProp: (
      state,
      action: PayloadAction<
        {
          key: string;
          value:
            | string
            | string[]
            | number
            | boolean
            | ChangeEvent<HTMLInputElement>
            | meterType
            | Phases
            | undefined;
        }[]
      >
    ) => {
      action.payload.forEach((p) => {
        let inputs: any = state.inputs;
        inputs[p.key] = p.value;
      });
    },
    resetConnections: (state) => {
      let inputs: Inputs = state.inputs;
      inputs.singlePhaseConnections = 0;
      inputs.threePhaseConnections = 0;
      inputs.connections = 0;
      inputs.multiSwitchConnections = [];
    },
    resetToInitialState: (state) => {
      state.inputs = initialState.inputs;
      state.studyType = initialState.studyType;
    }
  },
});

export const { setStudyType, updateInputsProp, resetConnections, resetToInitialState } =
  appSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectApp = (state: RootState) => state.app;

export default appSlice.reducer;
