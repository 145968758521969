import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const LoadingPleaseWait =  () =>
        <div className="container">
            <div className="row mt-5">
                <div className="col-12 text-center text-secondary">
                    <FontAwesomeIcon icon={faSpinner} size="6x" spin />
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-12">
                    <div className="text-center text-secondary">
                        please wait...
                    </div>
                </div>
            </div>                
        </div>

export default LoadingPleaseWait;