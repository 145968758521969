import { useEffect, useState } from "react";
import { userInterviewService } from "../services/userInterviewService";
import InterviewError from "./Interview/InterviewError";
import { InterviewParam } from "../models/models";
import LoadingPleaseWait from "./LoadingPleaseWait";
import DisplayStage from "./DisplayStage";

export interface IInterviewSelectProps {

}

type INTERVIEW_PARAMS_TYPE = InterviewParam[] | null;
const INTERVIEW_PARAMS_DEFAULT: INTERVIEW_PARAMS_TYPE = null;
type ERROR_MESSAGE_TYPE = string | null;
const ERROR_MESSAGE_DEFAULT: ERROR_MESSAGE_TYPE = null;

const InterviewSelect = () => {
    const [interviewParams, setInterviewParams] = useState<INTERVIEW_PARAMS_TYPE>(INTERVIEW_PARAMS_DEFAULT);
    const [errorMessage, setErrorMessage] = useState<ERROR_MESSAGE_TYPE>(ERROR_MESSAGE_DEFAULT);

    useEffect(() => {
        if (interviewParams === null && errorMessage === null) {
            getInterviewParams();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [interviewParams, errorMessage]);

    const getInterviewParams = () => {
        userInterviewService.getInterviewParams()
            .then((resp: any) => {
                setInterviewParams(resp?.data?.params as InterviewParam[]);
            })
            .catch((err: any) => {
                setErrorMessage("Failed to initialise interview");
            });
    }

    const reset = () => {
        setInterviewParams(INTERVIEW_PARAMS_DEFAULT);
        setErrorMessage(ERROR_MESSAGE_DEFAULT);
    }

    return (
        <>
            {
                ((errorMessage !== null) && (<InterviewError message={errorMessage} restartClick={() => reset() } />))
                || ((errorMessage === null) && (interviewParams === null) && (<LoadingPleaseWait />))
                || ((errorMessage === null) && (interviewParams !== null) && (<DisplayStage
                                                                                    interviewParams={interviewParams}
                                                                                    setErrorMessageCallback={(errorMessage) => setErrorMessage(errorMessage)}
                                                                                    />))
            }
        </>
    );
}


export default InterviewSelect;