import EANavbar from "../EANavbar/EANavbar";

interface IContent {
  children: any;
}

const Content = ({ children }: IContent) => {
  return (
    <>
      <EANavbar
        project={process.env.REACT_APP_DISPLAY_NAME!}
        version={process.env.REACT_APP_VERSION!}
        color="dark"
        bgColor="ea"
      />
      {children}
    </>
  );
};

export default Content;
