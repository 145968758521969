import Content from "../components/Content/Content";
import InterviewSelect from "../components/InterviewSelect";

import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";
import { ClickAnalyticsPlugin } from "@microsoft/applicationinsights-clickanalytics-js";

const connectionString = process.env.REACT_APP_APPINSIGHTS_CONNECTION_STRING;
if (connectionString !== undefined) {
    //console.log("envConnectionString=", connectionString, process.env);
    const browserHistory = createBrowserHistory();

    var clickPluginInstance = new ClickAnalyticsPlugin();
    const clickPluginConfig = {
        autoCapture: true,
    };

    var reactPlugin = new ReactPlugin();

    var appInsights = new ApplicationInsights({
        config: {
            connectionString: connectionString,
            enableAutoRouteTracking: true,
            extensions: [reactPlugin, clickPluginInstance],
            extensionConfig: {
                [reactPlugin.identifier]: { history: browserHistory },
                [clickPluginInstance.identifier]: clickPluginConfig,
            },
        },
    });
    appInsights.loadAppInsights();
}

const App = () => {
  return (
    <Content>
      <InterviewSelect />
    </Content>
  );
};

export default App;
