import { useState } from "react";
import { useLocalStorage as useLocalStorageHook } from "usehooks-ts";

const { REACT_APP_USE_LOCAL_STORAGE, REACT_APP_LOCAL_STORAGE_EXPIRY_SECONDS } = process.env;
const shouldUseLocalStorage = REACT_APP_USE_LOCAL_STORAGE === "true";
const expirySeconds = parseInt(REACT_APP_LOCAL_STORAGE_EXPIRY_SECONDS as string);

interface ILocalStorageExpiry<T> {
    _persistedAt: number,
    value: T,
}

const getValueWithExpiry = <T>(value: T): ILocalStorageExpiry<T> => {
    return {
        _persistedAt: Date.now(),
        value: value,
    }
}

const useLocalStorageWithExpiration = <T>(key:string , initialValue: T): [T, React.Dispatch<React.SetStateAction<T>>] => {

    const [valueWithExpiry, setValueWithExpiry] = useLocalStorageHook<ILocalStorageExpiry<T>>(key, getValueWithExpiry(initialValue))

    const startTime = (new Date(valueWithExpiry._persistedAt)).getTime();
    const endTime = (new Date()).getTime();

    const duration = endTime - startTime;
    const seconds = duration / 1000;

    const value = seconds <= expirySeconds ? valueWithExpiry.value : initialValue;

    const setWrapper: React.Dispatch<React.SetStateAction<T>> = (ssa: React.SetStateAction<T>) =>
        setValueWithExpiry(getValueWithExpiry(ssa instanceof Function ? ssa(value) : ssa));

    return [value, setWrapper];
}

const useLocalStorage = <T>(key:string , initialValue: T): [T, React.Dispatch<React.SetStateAction<T>>] => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    if (!shouldUseLocalStorage) return useState<T>(initialValue);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useLocalStorageWithExpiration(key, initialValue);
};

export default useLocalStorage;