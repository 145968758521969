import { IPostData } from "eatl.connectlite.nien.web.userinterview.component";
import axios from "axios";

const { REACT_APP_USERINTERVIEW_API: apiUrl } = process.env;

const url = apiUrl + "/UserInterview/SaveUserInterview" || "";
const paramsUrl = apiUrl + "/UserInterview/GetInterviewParams" || "";

const { post, get } = axios;

export const userInterviewService = {

    saveInterviewData: async (interviewdata: IPostData) => {
         return post(`${url}`, interviewdata);
    },

    getInterviewParams: async () => {
        return get(`${paramsUrl}`);
    }
}