import { Col, Container, Row } from "react-bootstrap";

export interface InterviewErrorProps {
    message: string;
    restartClick: any;
};

const InterviewError = (props: InterviewErrorProps | null) => {
    const msg: string = props?.message?.toString() ?? "An error occurred while processing your data, click the button below to try again";
    
    const handleTryAgainClick = () => {
        if (props?.restartClick) {
            props.restartClick();
        }
    };

    return (
        <Container className='mt-3'>
            <div className='alert alert-danger text-center'>
                <Row>
                    <Col className='fw-bold mt-3 mb-3'>
                        {msg}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <button className='btn btn-primary' onClick={handleTryAgainClick.bind(this)}>
                            Try Again
                        </button>  
                    </Col>
                </Row>
            </div>
        </Container>
    );
}

export default InterviewError;