import { Button, Modal } from "react-bootstrap"

interface IRestartModalProps {
    RestartCallback: () => void,
    ButtonText?: string,
    ModalCancelButtonText?: string,
    ModalMessageText?: string,
    Show: boolean
    HideModalCallback: () => void,
}
const RestartModal = ({
    RestartCallback,
    HideModalCallback,
    Show,
    ButtonText = "Restart",    
    ModalCancelButtonText = "Cancel",
    ModalMessageText = `Are you sure you want to ${ButtonText.toLowerCase()}? All progress will be lost!`,
} : IRestartModalProps) => {
    
    return <Modal show={Show} onHide={HideModalCallback}>
      <Modal.Body>{ModalMessageText}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={HideModalCallback}>
          {ModalCancelButtonText}
        </Button>
        <Button variant="danger" onClick={RestartCallback}>
        {ButtonText}
        </Button>
      </Modal.Footer>
    </Modal>
}

export default RestartModal;