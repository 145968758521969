import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./app/App";
import reportWebVitals from "./reportWebVitals";

import "./index.css";
import "./scss/custom.scss";
import { store, persistor } from "./app/store";

import { enableES5 } from "immer";
import { PersistGate } from "redux-persist/integration/react";
import Content from "./components/Content/Content";
import LoadingPleaseWait from "./components/LoadingPleaseWait";
enableES5();

const { REACT_APP_USE_LOCAL_STORAGE } = process.env;
const shouldUseLocalStorage = REACT_APP_USE_LOCAL_STORAGE === "true";


const LocalStorageSwitcher = () => <React.Fragment>
  {shouldUseLocalStorage
    ? <PersistGate loading={<Content><LoadingPleaseWait /></Content>} persistor={persistor}>
        <App />
      </PersistGate>
    : <App />
  }
</React.Fragment>

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <LocalStorageSwitcher />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
