import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import bsCustomFileInput from "bs-custom-file-input";
import { ChangeEvent, useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Modal,
  Row,
  Table,
} from "react-bootstrap";

import {
  resetConnections,
  resetToInitialState,
  selectApp,
  setStudyType,
  updateInputsProp,
} from "../../app/appSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { isEmpty } from "../../utils/isEmpty";
import "./react-tags-override.css";
import { IPostData } from "eatl.connectlite.nien.web.userinterview.component"

import jsPDF from "jspdf";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import budgetHeader from "../../img/budget-header.png";
import { DwellingTypes, Phases, PropertyTypes } from "eatl.connectlite.nien.web.userinterview.component";
import { IToast, apiErrorMessageToToast } from "../ErrorHandling/ToastHandler";
import useLocalStorage from "../../hooks/useLocalStorageWrapper";
import { meterType } from "../../models/models";
import RestartButtonWithModal from "../RestartButtonWithModal";
import RestartModal from "../RetartModal";

const { REACT_APP_MAP_HOST: maphost } = process.env;

export interface IStudyFormProps {
  InterviewData: IPostData | null,
  ReplaceToastCallback(toast: IToast[]): void,
  AppendToastCallback(toastToAppend: IToast[]): void,
  ResetCallback: () => void,
}

interface IStudyFormState {
  screenshot: string,
  quoteRef: string,
  quoteDateTime: string,
  postcode: string,
  priceBookName: string,
  totalCost: string,
  contestableTotalCost: string,
  nonContestableTotalCost: string,
  priceBookItems: any[],
  showModal: boolean,
  buildingOverride: boolean
}

const initialState: IStudyFormState = {
  screenshot: "",
  quoteRef: "",
  quoteDateTime: "",
  postcode: "",
  priceBookName: "",
  totalCost: "",
  contestableTotalCost: "",
  nonContestableTotalCost: "",
  priceBookItems: [],
  showModal: false,
  buildingOverride: false
}

const StudyForm = (props: IStudyFormProps) => {
  const dispatch = useAppDispatch();
  const app = useAppSelector(selectApp);
  const { inputs } = app;
  const [state, setState] = useLocalStorage<IStudyFormState>("ConnectLite.External.StudyForm.state", initialState);

  useEffect(() => {
    bsCustomFileInput.init();
    //console.log(inputs.multiSwitchConnections);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.addEventListener("message", handleEvent, false);

    return () => {
      window.removeEventListener("message", handleEvent, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    checkSetInterviewData();
  }, []);

  const resetResponse = () => {
    setState(initialState);
  };


  const resetAndTriggerCallback = async () => {
    resetResponse();
    dispatch(resetToInitialState());

    if (props.ResetCallback) {
      props.ResetCallback();
    }
  }

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
    let frame = document.getElementById("connectlite");
    if (isIFrame(frame) && frame.contentWindow) {
      //console.log("Sending inputs to frame");
      //console.log(inputs);
      frame.contentWindow.postMessage(inputs, "*");
      resetResponse();
    }
  }

  const isIFrame = (input: HTMLElement | null): input is HTMLIFrameElement =>
    input !== null && input.tagName === "IFRAME";


  const handleEvent = (e: any) => {
    //console.log(e.data.message ?? "No message in data");
    const data = e.data.message;
    if (data) {
      resetResponse();
      const parsedData = JSON.parse(data);
      // console.log(parsedData);
      // console.log(parsedData.inputs);
      // console.log(parsedData.billOfMaterials);

      //const { inputs } = parsedData.inputs;
      //const { billOfMaterials } = parsedData.billOfMaterials;
      // console.log(inputs);
      // console.log(billOfMaterials);


      const hasInputs = !isEmpty(parsedData.inputs);
      const hasBillOfMaterials = !isEmpty(parsedData.billOfMaterials);

      setState({
        ...state,
        quoteRef: hasInputs ? parsedData.inputs.quotationRef : state.quoteRef,
        quoteDateTime: hasInputs ? parsedData.inputs.quotationDateTime : state.quoteDateTime,
        postcode: hasInputs ? parsedData.inputs.searchValue : state.postcode,
        screenshot: hasBillOfMaterials ? parsedData.billOfMaterials.screenshot : state.screenshot,
        priceBookName: hasBillOfMaterials ? parsedData.billOfMaterials.priceBookName : state.priceBookName,
        totalCost: hasBillOfMaterials ? parsedData.billOfMaterials.totalCost : state.totalCost,
        contestableTotalCost: hasBillOfMaterials ? parsedData.billOfMaterials.contestableTotalCost : state.contestableTotalCost,
        nonContestableTotalCost: hasBillOfMaterials ? parsedData.billOfMaterials.nonContestableTotalCost : state.nonContestableTotalCost,
        priceBookItems: hasBillOfMaterials ? parsedData.billOfMaterials.items : state.priceBookItems,
        buildingOverride: hasInputs ? parsedData.inputs.buildingOverride : state.buildingOverride
      });
    }

    if (e.data?.invalidPostCode) {
      props.AppendToastCallback?.([apiErrorMessageToToast(`"${e.data?.invalidPostCode}" is not a valid postcode`)]);
    }
    if (e.data?.restartClicked) {
      setState({
        ...state,
        showModal: true,
      });
    }
  };

  const hideModel = () => {
    setState({
      ...state,
      showModal: false,
    });
  }

  const getConnections = () => {
    let value = 0;
    if (props.InterviewData) {
      if (props.InterviewData.propertyType === PropertyTypes.Commercial) {
        value = props.InterviewData.singlePhaseConnections > 0 ? props.InterviewData.singlePhaseConnections : props.InterviewData.threePhaseConnections > 0 ? props.InterviewData.threePhaseConnections : 1;
      }

    }
    return value;
  }

  const getMultiSwitchConnections = () => {
    let value : string[] = [];
    if (props.InterviewData) {
      if (props.InterviewData.propertyType === PropertyTypes.Domestic) {
        if (props.InterviewData.dwellingType === DwellingTypes.ApartmentBlock) {
          value = (props.InterviewData.apartments.length > 0) ? [`${props.InterviewData.apartments.length}`] : [];
        }
      }

    }
    return value;    
  }

  const getMeterType: () => meterType = () => {
    let value: meterType = "wc-meter-connections";

    if (props.InterviewData) {
      if (props.InterviewData.propertyType === PropertyTypes.Domestic) {
        if (props.InterviewData.dwellingType === DwellingTypes.ApartmentBlock) {
          value = "ms-meter-connections";
        }
      } else if (props.InterviewData.propertyType === PropertyTypes.Commercial) {
        value = "ct-meter-connections";
      }
    }
    return value;
  }

  const getSinglePhase = () => {
    let total: number = 0;

    if (props.InterviewData) {
      if (props.InterviewData.propertyType === PropertyTypes.Commercial) {
        if (props.InterviewData.phase === Phases.Single) {
          total = 1;
        }
      } else if (props.InterviewData.propertyType === PropertyTypes.Domestic) {
        if (props.InterviewData.dwellingType === DwellingTypes.ApartmentBlock) {

          if (props.InterviewData.phase === Phases.Single) {
            total = 1;
          }
          total += props.InterviewData.apartments?.length ?? 0;
        } else if (props.InterviewData.dwellingType === DwellingTypes.Domestic) {
          total += props.InterviewData.domesticDwellings?.length ?? 0;
        }
      }
    }

    if (total === 0) {
      total = inputs.singlePhaseConnections;
    }
    return total;
  };

  const getThreePhase = () => {
    let total: number = 0;

    if (props.InterviewData) {
      if (props.InterviewData.propertyType === PropertyTypes.Commercial) {
        if (props.InterviewData.phase === Phases.Three) {
          total = 1;
        }
      } else if (props.InterviewData.propertyType === PropertyTypes.Domestic) {
        if (props.InterviewData.dwellingType === DwellingTypes.ApartmentBlock) {
          if (props.InterviewData.phase === Phases.Three) {
            total = 1;
          }
        }
      }
    }

    if (total === 0) {
      total = inputs.threePhaseConnections;
    }
    return total;
  };

  const checkSetInterviewData = () => {
    if (props.InterviewData !== null) {
      //console.log("Interview data");
      //console.log(inputdata?.InterviewData);

      // console.log("Updting quoteref to '" + inputdata?.InterviewData.userId + "'");
      dispatch(
        updateInputsProp([
          { key: "quotationRef", value: props.InterviewData.interviewId ?? "" },
          { key: "searchType", value: "Postcode" },
          { key: "searchValue", value: props.InterviewData.postcode ?? "" },
          { key: "totalkVA", value: props.InterviewData?.totalKVA ?? 0 },
          { key: "singlePhaseConnections", value: getSinglePhase() },
          { key: "threePhaseConnections", value: getThreePhase() },
          { key: "connections", value: getConnections() },
          { key: "multiSwitchConnections", value: getMultiSwitchConnections() },
          { key: "landlordMeterPhase", value: props.InterviewData.landlordMeter?.phase },
          { key: "meterType", value: getMeterType() }
        ])
      );
    }
  };


  const [fileName, setFileName] = useState("NEW CONNECTION – BUDGETARY ESTIMATE");
  const [loadingPDF, setLoadingPDF] = useState(false);

  const exportPDF = () => {

    setLoadingPDF(true);

    const pdf = new jsPDF({
      format: "a4",
      unit: "px",
      orientation: "p",
    });

    const header = document.getElementById('budget-header') as HTMLElement;
    header.style.display = "block";

    const content = document.getElementById('content') as HTMLElement;

    const container = content.parentElement as HTMLElement;

    container.style.transformOrigin = 'top left';
    container.style.transform = `scale(${1 / window.devicePixelRatio})`;

    const subContent = document.getElementById('sub-content') as HTMLElement;
    subContent.style.transformOrigin = "top";
    subContent.style.transform = `scale(0.95)`; // Alternative to setting margin - doesnt affect available width for BoM

    const bootstrapContainerXLMaxWidth = 1140;

    if (container.clientWidth < bootstrapContainerXLMaxWidth) {
      container.style.maxWidth = `${bootstrapContainerXLMaxWidth}px`;
      container.style.width = `${bootstrapContainerXLMaxWidth}px`;
    }


    const pageWidthPixels = pdf.internal.pageSize.width;
    const contentScrollWidthPixels = content.scrollWidth;
    const scale = pageWidthPixels / contentScrollWidthPixels;

    const pageHeightPixels = pdf.internal.pageSize.height;

    const contentTop = content.getBoundingClientRect().top + window.scrollY;

    const marginTop = 20;
    const marginBottom = 30;
    const marginLeft = 0;
    const marginRight = 0;

    const yMargin = marginTop + marginBottom;

    const yAdjust = (0 - contentTop);

    const scaledUpPageHeightPixels = ((pageHeightPixels - yMargin) / scale);

    const networkMapHeaderDiv = document.getElementById('networkMapHeaderDiv') as HTMLElement;
    const networkMapImage = document.getElementById('networkMapImage') as HTMLElement;
    const networkMapImageBottom = scaleForWindowDevicePixelRatio(networkMapImage.getBoundingClientRect().bottom + window.scrollY + yAdjust);
    
    const networkMapHeaderDivTop = scaleForWindowDevicePixelRatio(networkMapHeaderDiv.getBoundingClientRect().top + window.scrollY + yAdjust);


    if (networkMapImageBottom > scaledUpPageHeightPixels)
    {
      networkMapHeaderDiv.style.paddingTop = `${(scaledUpPageHeightPixels - networkMapHeaderDivTop)}px`;
    }


    const disclaimerText = document.getElementById('nien_maptext') as HTMLElement;

    const disclaimerTextBottom = scaleForWindowDevicePixelRatio(disclaimerText.getBoundingClientRect().bottom + window.scrollY + yAdjust);

    const pagesWithContent = Math.ceil(disclaimerTextBottom / scaledUpPageHeightPixels);

    pdf.html(content, {
      //margin is ignored? https://github.com/parallax/jsPDF/issues/2924
      margin: [marginTop, marginLeft, marginBottom, marginRight],
      autoPaging: 'text',
      html2canvas: {
        scale: scale
      },
      callback: function () {
        // need to delete some blank pages first!        
        var pages = pdf.getNumberOfPages();
        //console.log("Total pdf pages " + pages);
        if (pages > pagesWithContent) {
          for (let j = pages; j > pagesWithContent; j--) {
            pdf.deletePage(j);
            //console.log("Deleted blank page " + j);
          }
        }

        // add page numbers
        pages = pdf.getNumberOfPages();
        if (pages > 1) {
          const pageWidth = pdf.internal.pageSize.width;  //Optional
          const pageHeight = pdf.internal.pageSize.height;  //Optional
          pdf.setFontSize(7);  //Optional
          for (let j = 1; j <= pages; j++) {
            let horizontalPos = pageWidth / 2;  //Can be fixed number
            let verticalPos = pageHeight - 10;  //Can be fixed number
            pdf.setPage(j);
            pdf.text(`Page ${j} of ${pages}`, horizontalPos, verticalPos, {
              align: 'center'  //Optional text styling});
            })
          }
        }

        //window.open(pdf.output('bloburl'));
        pdf.save(`${fileName} - ${state.quoteRef}.pdf`);

        header.style.display = "none";
        subContent.style.transformOrigin = "";
        subContent.style.transform = "";
        networkMapHeaderDiv.style.paddingTop = '';
        container.style.maxWidth = '';
        container.style.width = '';

        container.style.transformOrigin = '';
        container.style.transform = '';

        setLoadingPDF(false);
      }
    });
  };

  const scaleForWindowDevicePixelRatio = (value: number) => value * window.devicePixelRatio;


  return (
    <Container>
      <RestartModal
        Show={state.showModal}
        RestartCallback={resetAndTriggerCallback}
        HideModalCallback={hideModel}
      />
      {state.screenshot == "" && (
        <>
          <Row className="mt-3">
            <Col className="mb-4 border" style={{ height: "500px" }}>
              <iframe
                title="connectlite"
                id="connectlite"
                src={maphost}
                frameBorder="0"
                style={{
                  zIndex: 1,
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  top: "0px",
                  left: "0px",
                  border: "none",
                }}
                onLoad={handleSubmit}
                allowFullScreen
                allow="clipboard-write"
              ></iframe>
            </Col>
          </Row>
        </>
      )}

      {state.screenshot != "" && (
        <>
          <div id="content">
            <div id="budget-header" style={{ display: "none" }}>
              <Row className="mb-4">
                <img src={budgetHeader} />
              </Row>
            </div>
            <div id="sub-content">
              <Row className="my-4">
                <h2 className="nien_header fix_pdf_letter_spacing">NEW CONNECTION – BUDGETARY ESTIMATE</h2>
              </Row>
              <Row>
                <Table size="sm" responsive>
                  <tbody>
                    <tr>
                      <td><h4 className="text-left w-20">Ref: {state.quoteRef}</h4></td>
                      <td><h4 className="text-left">Date: {state.quoteDateTime}</h4></td>
                    </tr>
                  </tbody>
                </Table>
              </Row>
              
              <Row className="mt-4 mb-2">
                    <div className="nien_subheader fix_pdf_letter_spacing">Your Budget Estimate</div>
                  </Row>
                  <Row className="mt-2 mb-4">
                    <Col sm={6} lg={4}>
                    <div className="nien_subheader nien_subheader_grey fix_pdf_letter_spacing">Total Cost</div>
                    <div className="nien_estimate">£{parseFloat(state.totalCost).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</div>
                    </Col>
                  </Row>
                  <Row className="mt-2 mb-4">
                    <Col sm={6} lg={4}>
                    <div className="nien_subheader nien_subheader_grey fix_pdf_letter_spacing">Non-Contestable</div>
                    <div className="nien_estimate">£{parseFloat(state.nonContestableTotalCost).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</div>
                    </Col>
                    <Col sm={6} lg={4}>
                    <div className="nien_subheader nien_subheader_grey fix_pdf_letter_spacing">Contestable</div>
                    <div className="nien_estimate">£{parseFloat(state.contestableTotalCost).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</div>
                    </Col>
                  </Row>
              <Row className="my-4">
                <div className="nien_subheader fix_pdf_letter_spacing">Your Connection Requirements</div>
                <Table size="sm" responsive>
                  <tbody>
                    <tr>
                      <td><h5 className="text-left w-20">Postcode:</h5></td>
                      <td><h5 className="text-left">{state.postcode}</h5></td>
                    </tr>
                    {props.InterviewData?.propertyType === 1 && props.InterviewData?.domesticDwellings != null && (
                      props.InterviewData?.domesticDwellings.map((answer, i) => {
                        return (
                          <tr key={i}>
                            <td><h5 className="text-left w-20 nowrap">Connection{props.InterviewData?.domesticDwellings.length !== undefined ? props.InterviewData?.domesticDwellings.length > 1 ? (" " + (i + 1)) : "" : ""}:</h5></td>
                            <td>
                              <h5 className="text-left">Domestic Connection - {answer.propertyType.replace(/([A-Z])/g, (key) => ` ${key}`)}</h5>
                              {answer.evCharger && answer.evCharger > 0 ? <h5>{answer.evCharger} kVA EV Charger</h5> : ""}
                              {answer.heatPump && answer.heatPump > 0 ? <h5>{answer.heatPump} kVA Heat Pump</h5> : ""}
                            </td>
                          </tr>
                        )
                      })
                    )}
                    {props.InterviewData?.propertyType === 1 && (
                      props.InterviewData?.apartments.map((answer, i) => {
                        return (
                          <tr key={i}>
                            <td><h5 className="text-left w-20 nowrap">Connection{props.InterviewData?.apartments.length !== undefined ? props.InterviewData?.apartments.length > 1 ? (" " + (i + 1)) : "" : ""}:</h5></td>
                            <td>
                              <h5 className="text-left">Domestic Connection - Apartment</h5>
                              {answer.evCharger && answer.evCharger > 0 ? <h5>{answer.evCharger} kVA EV Charger</h5> : ""}
                              {answer.heatPump && answer.heatPump > 0 ? <h5>{answer.heatPump} kVA Heat Pump</h5> : ""}
                            </td>
                          </tr>
                        )
                      })
                    )}
                    {props.InterviewData?.propertyType === 2 && (
                      <>
                        <tr>
                          <td><h5 className="text-left w-20">Connection:</h5></td>
                          <td>
                            <h5 className="text-left">Commercial Connection - {props.InterviewData?.phase === 1 ? "Single Phase" : props.InterviewData?.phase === 3 ? "Three Phase" : ""}</h5>
                          </td>
                        </tr>
                        <tr>
                          <td><h5 className="text-left w-20">Total kVA:</h5></td>
                          <td><h5 className="text-left">{inputs.totalkVA} kVA</h5></td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </Table>
              </Row>
              <Row className="my-4">
                <div className="nien_subheader fix_pdf_letter_spacing" id="networkMapHeaderDiv">Network Map</div>
              </Row>
              <Row className="my-4">
                <>
                  <img src={state.screenshot} id="networkMapImage" />
                </>
              </Row>
              <Row className="my-4">
                <div className="nien_maptext" id="nien_maptext">The above costs are based on a high level desktop study only, actual costs of the connection works may differ significantly following more detailed design assessment, environmental impact studies, taking account of any wayleaves, consents, and the construction sequence of the works etc. This budget estimate does not constitute an offer of terms for NIE Networks to carry out these works.
                  I hope that this is sufficient at this stage to allow you to develop your feasibility analysis, but please contact our connections team if you would like to progress with a formal offer.
                </div>
              </Row>
              {(state.buildingOverride === true) && (
                  <Row className="my-4">
                    <div className="nien_maptext" id="nien_maptext">Please note, drawing the site over existing buildings has been allowed in this quote.</div>
                  </Row>
                )
              }
              </div>
          </div>
          <Row>
            <Button
              id="btnSavePdf"
              color="secondary"
              size="sm"
              className="pdf_button"
              onClick={() => exportPDF()}
            >
              {loadingPDF ? <FontAwesomeIcon icon={faSpinner} spin /> : "Save PDF"}
            </Button>
            <RestartButtonWithModal
              RestartCallback={resetAndTriggerCallback}
              ButtonVariant={"outline-secondary"}
              ButtonSize="sm"
              ButtonClassName={"pdf_button"}
              ButtonDisabled={loadingPDF}
              ConfirmWithModal={false}
            />
          </Row>
          <Row>
            <p></p>
          </Row>
          <Row>
            <p></p>
          </Row>
        </>
      )}
    </Container>
  );
};

export default StudyForm;